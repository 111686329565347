import { template as template_cb93b7f74643452e9fa3e923f804eef3 } from "@ember/template-compiler";
const FKText = template_cb93b7f74643452e9fa3e923f804eef3(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
