import { template as template_8c4fabb53e2e4475ad7677574c2bebf3 } from "@ember/template-compiler";
const FKLabel = template_8c4fabb53e2e4475ad7677574c2bebf3(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
