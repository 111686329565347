import { template as template_be0aaf4e069049deb44f451a259ac250 } from "@ember/template-compiler";
const WelcomeHeader = template_be0aaf4e069049deb44f451a259ac250(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
